<template>
  <div class="warpConss">
    <!-- {{isOutsourcing+'sssss'}} -->
    <el-collapse accordion v-model="activeNames">
      <el-collapse-item v-for="(grounp, grounpIndex) in CourseList" :key="grounpIndex" :name="grounpIndex">
        <template slot="title">
          <div class="title-grounp">
            <div class="dian"></div>
            {{ grounp.name }}
          </div>
        </template>
        <div class="chat-item" v-for="(item, index) in grounp.list" :key="index">
          <div class="item-title" @click.stop="check(item, grounp)">
            <i class="iconfont icon-a-Union21"></i>
            <div class="title ellipsis">
              {{ item.name }}
            </div>
          </div>
          <div class="but-list">
            <div>
              <div
                class="keep-learn"
                v-if="ifClass ? dealDocType(item, 2) : item.isHasContantJY == 'true'"
                @click.stop="ktData(item, 5)"
              >
                随堂讲义
              </div>
              <div
                class="keep-learn"
                v-if="ifClass ? item.contactList && item.contactList.length > 0 : item.isHasContant == 'true'"
                @click.stop="cgeckTask(item)"
              >
                随堂测试
              </div>
              <div
                class="keep-learn"
                v-if="ifClass ? dealDocType(item, 1) : item.isHasDoc == 'true'"
                @click.stop="ktData(item, 3)"
              >
                随堂资料
              </div>
            </div>
            <!--   <div class="keep-learn" v-if="item.isHasContant == 'true'" @click="ktData(item, 5)">随堂讲义</div>
            <div class="keep-learn" v-if="item.isHasContant == 'true'" @click="cgeckTask(item)">随堂测试</div>
            <div class="keep-learn" v-if="item.isHasDoc == 'true'" @click="ktData(item, 3)">随堂资料</div> -->
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="warpCpons" @touchmove.stop.prevent="stop()" v-if="dialogVisible">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click="tChuang" />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getClassCourseById } from "@/api/home";
import { IsLive } from "@/api/emun";
import { getInfo, getDomainToken } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
export default {
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      ifDialog: false,
      dialogList: null,
      domainjwt: getDomainToken(),
      activeNames: 0
    };
  },
  props: {
    id: {},
    courseId: {},
    list: {},
    IsPurchase: {
      type: Boolean,
    },
    playid: {},
    classId: {},
    isOutsourcing: {
      type: Number,
    },

    ifClass: {
      type: Boolean,
    },
    isFreeze: null
  },
  watch: {
    list() {
      this.CourseList = this.list;
      for (let item of this.CourseList) {
        if (!item.list) {
          item.list = item.audioLessons;
        }
      }
    },
  },
  created() {
    this.userInfo = getInfo();
    this.CourseList = this.list;
    for (let item of this.CourseList) {
      if (!item.list) {
        item.list = item.audioLessons;
      }
    }
  },
  methods: {
    /* 判断是否有讲义 */
    dealDocType(item, type) {
      let temp = false;
      if (item.docList && item.docList.length > 0) {
        item.docList.forEach((element) => {
          if (element.docType == type) {
            temp = true;
          }
        });
      }
      return temp;
    },
    tChuang() {
      this.dialogVisible = false;
    },
    stop() {
      return;
    },
    //观看回放
    check(item, groupId) {
       let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        this.$forceUpdate();
        return;
      }
      if (this.isFreeze == 1) {
        this.$message.error("课程已冻结");
        return;
      }
      if (this.isOutsourcing == 1) {
        this.ifDialog = true;
        this.dialogList = item;
      } else {
        let sub = {
          id: item.id,
          uservideoConfig: item.videoConfig,
          courseId: this.id,
          classId: this.classId,
          groupId: groupId.id,
          typebs: 2,
        };
        let data = Vue.prototype.export(sub);
        this.$router.push(`/audioCouser/audioChatView?${data}`);
      }
    },
    // 课堂作业
    cgeckTask(item) {
      let sId = item.sId;
      let title = item.title;
      if (this.ifClass) {
        title = item.contactList[0].sjName;
        sId = item.contactList[0].cContactid;
      }
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            title,
            sId,
            2,
            7,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            item.id,
            this.classId
          );
        }
      });
    },
    //随堂资料
    ktData(item, type) {
       let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      window.open(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`, "_blank");

      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    },
  },
};
</script>
<style lang="less" scoped>
.warpConss {
  padding: 32px;
  background: #fff;
}
/deep/ .el-collapse {
  border: none !important;
  .el-collapse-item__header {
    height: 46px;
    border: none !important;
    .title-grounp {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      .dian {
        width: 6px;
        height: 6px;
        background: #333333;
        opacity: 1;
        border-radius: 50%;
        margin-right: 8px;
        flex-shrink: 0;
      }
    }
  }
}
/* 购买提示 */
.warpCpons {
  // position: absolute;
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.6);
  div {
    width: 610px;
    height: auto;
    display: flex;
    z-index: 20;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0);
    .imga {
      width: 100%;
      height: 420px;
    }
    .imgb {
      width: 36px;
      height: 36px;
      margin-top: 30px;
      cursor: pointer;
    }
    span {
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6c90fe;
      margin-top: -160px;
    }
  }
}
/* 试听 */
.audition {
  width: 48px;
  height: 20px;
  background: #ffedeb;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 20px;
  margin-left: 8px;
}
/* 节 */
.chat-item {
  border-bottom: 1px solid #eee;
  height: 56px;
  display: flex;
  justify-content: space-between;
  margin: 0px 14px;
  .item-title {
    display: flex;
    width: calc(100% - 300px);
    align-items: center;
    cursor: pointer;
    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      flex-shrink: 0;
    }
    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
    }
  }

  .but-list {
    flex-shrink: 0;
    display: flex;
    width: 240px;
    justify-content: flex-end;
    align-items: center;
    .keep-learn {
      margin-right: 12px;
      flex-shrink: 0;
      width: 72px;
      height: 24px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ff5e51;
      padding: 0px;
      text-align: center;
      line-height: 24px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>
<style lang="less" >
</style>
