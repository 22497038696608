<template>
  <div>
    <div v-loading="loading" class="room">
      <el-radio-group class="radioWrap" v-model="radio" @change="yearChange">
        <el-radio v-for="(item, index) in yearList" :key="index" :label="item">{{ item }}</el-radio>
        <!--   <el-radio label="2020">2020</el-radio>
          <el-radio label="2019">2019</el-radio> -->
      </el-radio-group>
      <div class="roomWrap">
        <template v-for="(item, index) in roomList">
          <div class="roomItem" :key="index">
            <img v-if="item.roomStatus !== 1" src="@/assets/img/study/kc.png" class="roomImgItem" />
            <img v-else src="@/assets/img/study/finishExmRoom.png" class="roomImgItem" />
            <div class="roomItemContent">
              <div class="roomItemTitle">
                <div class="roomItemTag" v-if="item.type == 2">限邀请</div>
                <span class="roomItemText twoEllipsis">{{ item.typeName }}</span>
              </div>
              <div class="timeText"> <img src="@/assets/img/study/tm.png" alt=""> {{ item.startTime + "-" + item.endTime
                }}</div>
              <div class="tips"><!-- {{item.subjectNum}}套试卷, -->共{{ item.subjectNum }}题</div>
            </div>
            <div v-if="item.roomStatus !== 1 && testRoom(item)" class="btnItem" @click="goRoom(item)">
              进入考场
            </div>
          </div>
          <!-- <el-divider :key="index"></el-divider> -->
        </template>
        <div class="noMsg" v-if="roomList.length < 1">暂无内容~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
let know = new Know();
export default {
  props: ["roomVisible", "IsPurchase", "classesId", 'direction'],

  data() {
    return {
      radio: "2021",
      roomList: [],
      /*  IsPurchase: null, // 是否购买
      classesId: null, */
      userInfo: null,
      yearList: [],
      loading: false,

    };
  },
  created() {
    this.userInfo = getInfo();
    this.virtualTestList()
  },
  methods: {
    /* 选择年份 */
    yearChange() {
      this.selectVirtualRoomPcList();
    },
    testRoom(item) {
      return true;
      /* let startTime = new Date(item.startTime);
      let nowTime = new Date();
      if (startTime.getTime() < nowTime.getTime()) {
        return true;
      } else {
        return false;
      } */
    },
    /* 进入考场 */
    goRoom(item) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.IsPurchase) {
        let sub = {
          roomId: item.typeId,
          classId: this.classesId,
          IsPurchase: this.IsPurchase
        };
        let data = Vue.prototype.export(sub);
        this.$router.push(`/quesTion/examRoom/examRoomDetail?${data}`);
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法答题, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    expired(item) {

      if (item.roomStatus == 1) {
        this.$message({
          type: "warning",
          message: "考场未开始",
        });
      } else if (item.roomStatus == 3) {
        this.$message({
          type: "warning",
          message: "考场已结束",
        });
      }
    },
    /* 专属考场列表 */
    selectVirtualRoomPcList() {
      if (this.direction) {
        let data = {
          classesId: this.classesId,
          coursePackageId: this.direction,
          yearName: this.radio
        }
        know.selectVirtualRoomPcPackageList(data).then((res) => {
          this.roomList = res.data;
          this.loading = false

        });
      } else {
        let params = {
          classesId: this.classesId,
          yearName: this.radio,
        };
        know.selectVirtualRoomPcList(params).then((res) => {
          this.roomList = res.data;
          this.loading = false

        });
      }
    },
    virtualTestList() {
      this.loading = true
      if (this.direction) {
        let data = {
          classesId: this.classesId,
          coursePackageId: this.direction
        }
        know.selectClassesCourseRoomList(data).then((res) => {
          if (res.code == 0) {
            for (let item of res.data) {
              item.passbai =
                item.passNum && item.passNum != "0" ? ((item.passNum / item.zrNum) * 100).toFixed(2) + "%" : "0%";
              item.year = item.startTime.substring(0, 4);
              this.yearList.push(item.startTime.substring(0, 4));
              this.yearList = Array.from(new Set(this.yearList));
              this.yearList.sort(function (a, b) {
                return b - a;
              });
              this.radio = this.yearList[0];
            }
            this.selectVirtualRoomPcList();
          } else {
            this.loading = false

          }
        });
      } else {
        know.selectClassesRoomList(this.classesId).then((res) => {
          if (res.code == 0) {
            for (let item of res.data) {
              item.passbai =
                item.passNum && item.passNum != "0" ? ((item.passNum / item.zrNum) * 100).toFixed(2) + "%" : "0%";
              item.year = item.startTime.substring(0, 4);
              this.yearList.push(item.startTime.substring(0, 4));
              this.yearList = Array.from(new Set(this.yearList));
              this.yearList.sort(function (a, b) {
                return b - a;
              });
              this.radio = this.yearList[0];
            }
            this.selectVirtualRoomPcList();
          } else {
            this.loading = false

          }
        });
      }
    },
    /* 关闭回调 */
    roomClose() {
      this.radio = "2021";
      this.IsPurchase = null;
      this.$emit("roomClose");
    },
  },
};
</script>

<style lang="less" scoped>
.room {
  width: 680px;
  height: 419px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 20px;
  overflow-y: scroll;

  .radioWrap {
    margin-bottom: 20px;
  }

  .roomWrap {
    // height: 440px;s
    // overflow: auto;

    .roomItem {
      width: 640px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      padding: 20px;
      margin-bottom: 10px;
      align-items: center;

      .roomImgItem {
        width: 80px;
        height: 80px;
        margin-right: 12px;
      }

      .itemTag {
        width: 38px;
        height: 16px;

        border-radius: 8px 0 12px 0;
      }

      .roomItemContent {
        width: 425px;

        .roomItemTitle {

          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #000000;

          .roomItemTag {
            display: inline-block;
            min-width: 34px;
            padding: 3px 6px;
            background-color: #ffeee2;
            font-size: 11px;
            color: #ff7b1c;
            border-radius: 2px;
            margin-right: 4px;
          }

          .roomItemText {

            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }

        .timeText {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #999999;
          margin: 8px 0 10px 0;

          img {
            flex-shrink: 0;
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
        }

        .tips {
          width: fit-content;
          background: #FFF2E9;
          border-radius: 4px 4px 4px 4px;
          padding: 2px 8px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #FF7B1C;
        }
      }

      .btnItem {

        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #AAAAAA;
        cursor: pointer;
      }
    }
  }
}

.noMsg {
  text-align: center;
  line-height: 400px;
}
</style>