<template>
    <div class="educateWrap">
        <div v-if="detail && detail.teacherDtoVos" v-for="(item, index) in detail.teacherDtoVos" :key="index"
            class="ter_item">
            <div class="item_left">
                <div class="ter_name">
                    <div class="ter_uname">{{ item.isRecommend ? item.userName : item.nickname }}</div>
                    <div v-if="item.jobTitle" class="ter_jobTitle">{{ item.jobTitle }}</div>
                </div>
                <div class="textBlock">
                    <div class="floating_window" v-html="ImgSizeLimit(item.profile)" />

                </div>
            </div>
            <div class="item_right">
                <div class="ter_img">
                    <div class="ter_img_bg"></div>
                    <div class="ter_img_bg ter_img_bg1">
                        <img :src="item.terImg" alt=""></img>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    props: ['detail'],
    data() {
        return {
            classMeetingList: []
        }
    },
    created() {
        console.log(this.detail, 'detail');

    }
}
</script>

<style lang="less" scoped>
.educateWrap {
    // padding-bottom: 20px;
    margin-bottom: 20px;

    .ter_item {
        width: 95%;
        height: 168px;
        background: url('~@/assets/img/Home/bg99.png');
        background-size: 100% 100%;
        border-radius: 10px 10px 10px 10px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .item_left {
            padding: 27px 31px;

            .ter_name {
                display: flex;
                align-items: center;

                .ter_uname {

                    font-family: PingFang SC, PingFang SC;
                    font-weight: bold;
                    font-size: 20px;
                    color: #333333;
                }

                .ter_jobTitle {
                    padding: 1px 14px;
                    border-radius: 15px;
                    border: 1px solid #FF5E51;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FF5E51;
                    margin-left: 10px;
                }
            }

            .textBlock {

                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                line-height: 24px;
                margin-top: 14px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
        }

        .item_right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-right: 40px;

            .ter_img {
                display: flex;
                position: relative;

                .ter_img_bg {
                    position: relative;
                    width: 124px;
                    height: 149px;
                    background: #FF9E77;
                    border-radius: 62px 62px 0px 0px;
                    overflow: hidden;
                    bottom: 0;

                    img {
                        width: 100%;
                        height: fit-content;
                    }
                }

                .ter_img_bg1 {
                    position: absolute;
                    left: 10px;
                }
            }
        }
    }
}
</style>