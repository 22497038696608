import { render, staticRenderFns } from "./examView.vue?vue&type=template&id=247fbcea&scoped=true&"
import script from "./examView.vue?vue&type=script&lang=js&"
export * from "./examView.vue?vue&type=script&lang=js&"
import style0 from "./examView.vue?vue&type=style&index=0&id=247fbcea&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247fbcea",
  null
  
)

export default component.exports