<template>
  <!-- 右侧推荐 -->
  <div class="appleRight">
    <!-- 块一 -->
    <div class="appleRightTop">
      <div class="love-two-title">
        <div class="text">
          <slot>猜你喜欢</slot>
          <img class="icon" src="@/assets/img/Home/tips.png" />
        </div>
      </div>
      <!-- <div class="appleRightTopa"><slot>猜你喜欢</slot></div> -->
      <!-- 内容1 -->
      <div v-for="(item, index) in list" :key="index" class="appleRightTopb" @click="detail(item)">
        <div class="appleRightTopbs pointer">
          <img class="img" :src="item.img" />
          <div class="appleRightTopbsR">
            <div class="title twoEllipsis">
              {{ type == 1 ? item.tiitle : item.title }}
            </div>
            <div v-if="ifteacher" class="detail-ter m-t-12 flex-c">
              <div class="ter-list">
                <div class="terbox">
                  <el-image class="ter-img" :src="item ? item.tImg : ''" fit="cover" />
                </div>
                <span class="ter">讲师：{{ item.terName }}</span>
              </div>
            </div>
            <span v-else-if="university != 1" class="price">¥ {{ item.price ? item.price : 0 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 块二 -->
    <div class="appleRightCen" />
    <!-- 块三 -->
    <!--    <div class="appleRightBtm">
      <div class="appleRightBtma">聚学云网校</div>
      <div class="appleRightBtmb">
        <div class="appleRightBtmbs">
          <img src="@/assets/img/kecheng/gzh.png" />
          <p>聚学云网校公众号</p>
        </div>
        <div class="appleRightBtmbs">
          <img src="@/assets/img/kecheng/xcx.png"  />
          <p>聚学云网校小程序</p>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { getClassRelevant } from '@/api/home'
import { getDomain } from '@/api/cookies'

export default {
  props: ['type', 'id', 'kind', 'size', 'pageNum', 'ifteacher'],
  data() {
    return {
      list: [],
      domainInfo: {},
      university: ''
    }
  },
  created() {
    this.domainInfo = getDomain()
    if (this.domainInfo.eId == process.env.VUE_APP_university) {
      this.university = 1
    }

    /** 课程推荐
     *  1知识套餐课程  2直播课程  3录播课程 4公开课程 5资料包' */
    getClassRelevant(this.type, this.id).then((res) => {
      if (res.code == 0) {
        // const data = res.msg.filter((element) => this.kind == element.kind)

        this.list = res.msg.slice(0, 5)
      }
    })
  },

  methods: {
    /*   if (item.types == 1) {
        this.$router.push(`/zhiboclassxq?id=${item.id}`);
      } else if (item.types == 2) {
        this.$router.push(`/videoxq?id=${item.id}`);
      } else if (item.types == 3) {
      id=${item.id}`);
      } else if (item.types == 4) {
        this.$router.push(`/imgCouser/index?id=${item.id}`);
      } else if (item.types == 5) {
        this.$router.push(`/faceCouser/index?id=${item.id}`);
      } */
    // 跳转
    detail(row) {
      switch (this.type) {
        case 1:
          if (row.productLine == 3) {
            this.$router.push(`/VIP/VIPClassxq?id=${row.id}`)
          } else {
            this.$router.push(`/typeclassxq?id=${row.id}`)
          }
          break
        case 2:
          this.$router.push(`/zhiboclassxq?id=${row.id}`)
          break
        case 3:
          this.$router.push(`/videoxq?id=${row.id}`)
          break
        case 4:
          this.$router.push(`/openclassxq?id=${row.id}`)
          break
        case 5:
          this.$router.push(`/ketangziliao?id=${row.id}`)
          break
        case 7:
          this.$router.push(`/audioCouser/index?id=${row.id}`)
          break
        case 8:
          this.$router.push(`/imgCouser/index?id=${row.id}`)
          break
        case 10:
          this.$router.push(`/faceCouser/index?id=${row.id}`)
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.appleRight {
  width: 320px;
  height: fit-content;
  background: #ffffff;
  border-radius: 6px;

  .appleRightTop {
    width: 280px;
    height: auto;
    margin: 20px auto;

    .appleRightTopb {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .appleRightTopbs {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;

        .img {
          margin-top: 8px;
          margin-right: 12px;
          width: 120px;
          height: 67.5px;
          background: #c4c4c4;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          flex-shrink: 0;
        }

        .appleRightTopbsR {
          width: 145px;
          height: 70px;

          .title {
            height: 44px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }

          .clicks {
            font-size: 14px;
            color: #999;
          }

          .price {
            font-size: 18px;
            color: #f15817;
          }
        }
      }
    }
  }

  .appleRightCen {
    width: 100%;
    height: 20px;
    background: #f5f6fa;
  }

  .appleRightBtm {
    width: 280px;
    height: 200px;
    margin: auto;

    .appleRightBtma {
      margin-top: 20px;
      width: 100%;
      height: 20px;
      border-left: 3px solid #009aff;
      font-size: 18px;
      color: #333;
      margin-bottom: 2px;
      padding-left: 8px;
      line-height: 18px;
    }

    .appleRightBtmb {
      width: 100%;
      height: 144px;
      display: flex;
      margin-top: 30px;

      .appleRightBtmbs {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 100px;
          height: 100px;
        }

        p {
          width: 100%;
          height: 34px;
          font-size: 14px;
          color: #626262;
          line-height: 34px;
          text-align: center;
        }
      }
    }
  }
}

/* 列表-讲师 */
.detail-ter {
  .ter-list {
    display: flex;
    align-items: center;

    .terbox {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
      background: #a8abbe;
      border: none;
      overflow: hidden;
    }

    .ter-img {
      width: 24px;
    }

    .ter {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
    }
  }
}
</style>
