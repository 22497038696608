<template>
  <div class="classes-question">
    <el-collapse v-loading="loading" v-model="activeNames" class="kcList" accordion>
      <el-collapse-item v-for="(item, index) in questionName" :key="index" :name="index">
        <template slot="title">
          <div class="title">
            <div class="line" />
            <span>{{ item.name }}</span>
          </div>
        </template>
        <!-- 客观题题库 -->
        <question-item v-if="index == 0" :is-purchase="IsPurchase" :question-index="index"
          :question-list="questionList" />
        <!-- 易错错题库 -->
        <question-item v-else-if="index == 1" :is-purchase="IsPurchase" :question-index="index"
          :question-list="fallibleQuestionList" />
        <!-- 个人错题库 -->
        <!-- 未购买不展示个人错题库 -->
        <question-item v-else-if="index == 2" :is-purchase="IsPurchase" :question-index="index"
          :question-list="IsPurchase ? myWrongQuestionList : []" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { getClassesQuestionList, getAllFallibleQuestion, getMyWrongQuestion } from '@/api/question'
import questionItem from './question-item.vue'
import { getInfo } from '@/api/cookies'
export default {
  components: {
    questionItem
  },
  props: ['classesId', 'IsPurchase'],
  data() {
    return {
      questionName: [
        { name: '客观题题库' },
        { name: '易错错题库' },
        { name: '个人错题库' }
      ],
      loading: false,
      userInfo: {},
      activeNames: [0],
      questionList: [],
      fallibleQuestionList: [],
      myWrongQuestionList: []
    }
  },
  async created() {
    this.userInfo = getInfo()
    // 客观题题库
    this.loading = true
    const { data } = await getClassesQuestionList({
      classesId: this.classesId,
      userId: this.userInfo && this.userInfo.id
    })
    // 易错错题库
    const { data: data2 } = await getAllFallibleQuestion({
      classesId: this.classesId,
      userId: this.userInfo && this.userInfo.id
    })
    // 个人错题库
    const { data: data3 } = await getMyWrongQuestion({
      classesId: this.classesId,
      userId: this.userInfo && this.userInfo.id
    })
    this.questionList = data || []
    this.fallibleQuestionList = data2 || []
    this.myWrongQuestionList = data3 || []
    this.loading = false
  }
}
</script>

<style lang="less" scoped>
.classes-question {
  width: 680px;
  height: 419px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 20px;
  overflow-y: scroll;

  /deep/ .el-collapse {
    border: none;

    .el-collapse-item__header {
      border: none;
    }

    .el-collapse-item__wrap {
      border-bottom: #f5f5f5 1px solid;
    }

    .el-icon-arrow-right {
      transform: rotate(90deg);
    }

    .is-active.el-icon-arrow-right {
      transform: rotate(270deg);
    }
  }

  .title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;

    .line {
      width: 3px;
      height: 16px;
      background: #ff5e51;
      border-radius: 2px 2px 2px 2px;
      margin-right: 9px;
      display: inline-block;
    }
  }
}
</style>
