import request from "@/common/utils/request.js"
// 查询客观题课程列表
export function getClassesQuestionList(params) {
  return request({
    url: "/kecheng/classesQuestion/getClassesQuestionList",
    method: "get",
    params
  })
}

// 获取易错题库
export function getAllFallibleQuestion(params) {
  return request({
    url: "/kecheng/fallibleQuestion/getAllFallibleQuestion",
    method: "get",
    params
  })
}
// 查询我的错题库
export function getMyWrongQuestion(params) {
  return request({
    url: "/kecheng/wrongQuestion/getMyWrongQuestion",
    method: "get",
    params
  })
}

/***************************学习中心***************************************/

// 客观题-获取购买的套餐
export function getQuestionStudentCourseList(params) {
  return request({
    url: "/kecheng/classesQuestion/getQuestionStudentCourseList",
    method: "get",
    params
  })
}

// 易错题-获取购买的套餐
export function getFallibleStudentCourseList(params) {
  return request({
    url: "/kecheng/fallibleQuestion/getFallibleStudentCourseList",
    method: "get",
    params
  })
}

// 错题库-获取购买的套餐
export function getStudentCourseList(params) {
  return request({
    url: "/kecheng/wrongQuestion/getStudentCourseList",
    method: "get",
    params
  })
}


// 练习记录列表
export function getPracticeRecordList(params) {
  return request({
    url: "/kecheng/practiseRecord/getPractiseRecordList",
    method: "get",
    params
  })
}

// 获取客观题试做题目
export function getTryQuestionList(params) {
  return request({
    url: "/kecheng/classesQuestion/getTryQuestionList",
    method: "get",
    params
  })
}
// 获取客观题练习题目
// 模式 1-随机模式 2-练习模式
export function getAnswerQuestListByMode(params) {
  return request({
    url: "/kecheng/classesQuestion/getAnswerQuestListByMode",
    method: "get",
    params
  })
}
// 易错题-获取试题列表
// 模式 1-随机模式 2-练习模式
export function getFallibleAnswerQuestListByMode(params) {
  return request({
    url: "/kecheng/fallibleQuestion/getFallibleAnswerQuestListByMode",
    method: "get",
    params
  })
}
// 错题库-获取试题列表
// 模式 1-随机模式 2-练习模式
export function getWrongAnswerQuestListByMode(params) {
  return request({
    url: "/kecheng/wrongQuestion/getAnswerQuestListByMode",
    method: "get",
    params
  })
}
// 客观题提交接口
export async function addPcSubjectAnswer (params) {
  let fromData = JSON.parse(JSON.stringify(params))
  for (let item of fromData.questionSubjectPaperVoList) {
    if (item.questionType == 1 || item.questionType == 6) {
      await item.questionUserAnswer.sort()
      item.questionUserAnswer = item.questionUserAnswer.join(",")
    } else if (item.questionType == 5) {
      for (let child of item.childSubject) {
        if (child.questionType == 1 || child.questionType == 6) {
          if (child.questionUserAnswer) {
            await child.questionUserAnswer.sort()
          }
          child.questionUserAnswer = child.questionUserAnswer.join(",")
        }
      }
    }
  }
  let data = await request({
    url: `/kecheng/classesQuestion/addPcSubjectAnswer`,
    method: "POST",
    data: fromData,
  })
  return data
}
// 易错题-试卷提交答案
export async function addFalliblePcSubjectAnswer (params) {
  let fromData = JSON.parse(JSON.stringify(params))
  for (let item of fromData.questionSubjectPaperVoList) {
    if (item.questionType == 1 || item.questionType == 6) {
      await item.questionUserAnswer.sort()
      item.questionUserAnswer = item.questionUserAnswer.join(",")
    } else if (item.questionType == 5) {
      for (let child of item.childSubject) {
        if (child.questionType == 1 || child.questionType == 6) {
          if (child.questionUserAnswer) {
            await child.questionUserAnswer.sort()
          }
          child.questionUserAnswer = child.questionUserAnswer.join(",")
        }
      }
    }
  }
  let data = await request({
    url: `/kecheng/fallibleQuestion/addFalliblePcSubjectAnswer`,
    method: "POST",
    data: fromData,
  })
  return data
}
// 错题库-试卷提交答案
export async function addWrongPcSubjectAnswer (params) {
  let fromData = JSON.parse(JSON.stringify(params))
  for (let item of fromData.questionSubjectPaperVoList) {
    if (item.questionType == 1 || item.questionType == 6) {
      await item.questionUserAnswer.sort()
      item.questionUserAnswer = item.questionUserAnswer.join(",")
    } else if (item.questionType == 5) {
      for (let child of item.childSubject) {
        if (child.questionType == 1 || child.questionType == 6) {
          if (child.questionUserAnswer) {
            await child.questionUserAnswer.sort()
          }
          child.questionUserAnswer = child.questionUserAnswer.join(",")
        }
      }
    }
  }
  let data = await request({
    url: `/kecheng/wrongQuestion/addWrongPcSubjectAnswer`,
    method: "POST",
    data: fromData,
  })
  return data
}

// 获取练习报告答案
export function getPcSubjectAnswer(params) {
  return request({
    url: "/kecheng/classesQuestion/getPcSubjectAnswer",
    method: "get",
    params
  })
}