<template>
  <div>
    <template v-if="questionList.length > 0">
      <div v-for="(question, i) in questionList" :key="i">
        <div
          v-if="question.totalSubjectNum !== 0"
          class="question-item"
          :class="isPersonal ? 'item-width' : ''"
        >
          <div class="question-left">
            <img :src="styleColor.bankIcon" class="bank-icon" />
            <div>
              <div class="kc-name ellipsis">{{ question.kcName }}</div>
              <!-- 客观题库和易错题库可看到做题进度/正确率，个人错题只展示题目总数。 -->
              <template v-if="questionIndex == 0 || questionIndex == 1">
                <div v-if="question.stuSubjectNum != 0" style="display: flex">
                  <div v-if="isPersonal" class="num correctRate ellipsis">
                    <img src="@/assets/img/Home/question/ratio.png" />正确率
                    <span :class="styleColor.color"
                      >{{ question.correctRate }}%</span
                    >
                  </div>
                  <div class="num">
                    <img
                      src="@/assets/img/Home/question/progress-icon.png"
                    />进度
                    <span :class="styleColor.color"
                      >{{ question.stuSubjectNum }}/{{
                        question.totalSubjectNum
                      }}</span
                    >
                  </div>
                </div>
                <div v-else class="num">
                  共<span :class="styleColor.color">
                    {{
                      question.totalSubjectNum !== null
                        ? question.totalSubjectNum
                        : "0"
                    }}</span
                  >题
                </div>
              </template>
              <!-- 个人错题 -->
              <div v-else class="num">
                共<span :class="styleColor.color">
                  {{
                    question.totalSubjectNum !== null
                      ? question.totalSubjectNum
                      : "0"
                  }}</span
                >题
              </div>
            </div>
          </div>
          <div
            v-if="showBtn"
            class="contact"
            :class="[styleColor.color, styleColor.border]"
            @click="showSelect(question)"
          >
            {{ IsPurchase ? "练习" : "试做" }}
          </div>
        </div>
      </div>
    </template>
    <div
      v-else-if="questionList.length == 0"
      class="empty"
      :class="isPersonal ? 'item-width' : ''"
    >
      暂无题目
    </div>
    <el-dialog
      top="40vh"
      class="selectDialog"
      :close-on-click-modal="true"
      :modal-append-to-body="false"
      :visible.sync="showSelectDialog"
      :before-close="handleClose"
      append-to-body
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item-list"
        :class="styleColor.border"
        @click="goToQuesTion(index)"
      >
        <div class="item-box">
          <div class="item-left">
            <img
              :src="index == 0 ? styleColor.random : styleColor.test"
              :alt="item.title"
            />
            <div>
              <div class="tit">{{ item.title }}</div>
              <div class="msg">{{ item.msg }}</div>
            </div>
          </div>
          <img class="into" :src="styleColor.intoIcon" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // 题目列表
    questionList: {
      type: Array,
      default: [],
    },
    // 题库 0:客观题题库/1:易错错题库/2:个人错题库
    questionIndex: {
      type: Number,
      default: 0,
    },
    // 是否购买
    IsPurchase: {
      type: Boolean,
      default: false,
    },
    // 是否是我的学习中心
    isPersonal: {
      type: Boolean,
      default: false,
    },
    // 模板: blueOne 高校合伙人，blueTwo 同行升级
    styleClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      into: require("@/assets/img/Home/question/into.png"),
      intoBlueOne: require("@/assets/img/Home/question/into-blue-one.png"),
      bank: require("@/assets/img/Home/question/bank-icon.png"),
      bankBlueOne: require("@/assets/img/Home/question/bank-blue-one.png"),
      bankBlueTwo: require("@/assets/img/Home/question/bank-blue-two.png"),
      random: require("@/assets/img/Home/question/random.png"),
      randomBlueOne: require("@/assets/img/Home/question/random-blue-one.png"),
      test: require("@/assets/img/Home/question/test.png"),
      testBlueOne: require("@/assets/img/Home/question/test-blue-one.png"),
      list: [
        { title: "随机模式", msg: "随机弹出试题，做题后即可查看解析" },
        { title: "练习模式", msg: "20题为一组，答题完成提交后可查看对错解析" },
      ],
      showSelectDialog: false,
      kId: "",
      kcName: "",
    };
  },
  computed: {
    styleColor() {
      const data = {
        color: "",
        border: "",
        bankIcon: "",
      };
      switch (this.styleClass) {
        case "blueOne":
          data.color = "blueOne";
          data.border = "blueOneBorder";
          data.bankIcon = this.bankBlueOne;
          data.intoIcon = this.intoBlueOne;
          data.random = this.randomBlueOne;
          data.test = this.testBlueOne;
          break;
        case "blueTwo":
          data.color = "blueTwo";
          data.border = "blueTwoBorder";
          data.bankIcon = this.bankBlueTwo;
          data.intoIcon = this.intoBlueOne;
          data.random = this.randomBlueOne;
          data.test = this.testBlueOne;
          break;
        case "":
          data.color = "red";
          data.bankIcon = this.bank;
          data.intoIcon = this.into;
          data.random = this.random;
          data.test = this.test;
          break;
      }
      return data;
    },
    // 做题按钮显示逻辑
    showBtn() {
      if (this.questionIndex == 0) {
        return true;
      } else {
        if (this.IsPurchase) return true;
        return false;
      }
    },
  },
  methods: {
    showSelect(item) {
      this.kId = item.kId;
      this.kcName = item.kcName;
      // 未购买去试做 随机模式
      if (!this.IsPurchase) {
        if (
          item.totalSubjectNum == 0 ||
          item.totalSubjectNum == null ||
          item.totalSubjectNum == "null"
        ) {
          this.$message({
            type: "warning",
            message: "当前课程暂无题目",
          });
          return;
        }
        this.$router.push(
          `/quesTion/examRandom?classId=${this.$route.query.id}&kId=${this.kId}&kcName=${this.kcName}&examType=try&type=${this.questionIndex}`
        );
        return;
      }
      this.showSelectDialog = true;
    },
    handleClose() {
      this.showSelectDialog = false;
    },
    goToQuesTion(index) {
      // 随机模式
      if (index == 0) {
        this.$router.push(
          `/quesTion/examRandom?kId=${this.kId}&kcName=${this.kcName}&type=${this.questionIndex}`
        );
        return;
      } else {
        // 练习模式
        this.$router.push(
          `/quesTion/examTest?kId=${this.kId}&kcName=${this.kcName}&type=${this.questionIndex}`
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.question-item {
  width: 596px;
  height: 72px;
  background: #f9f9f9;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 10px;
  .question-left {
    margin-left: 12px;
    display: flex;
    .bank-icon {
      width: 51px;
      height: 51px;
      margin-right: 10px;
    }
    .kc-name {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-bottom: 3px;
      max-width: 430px;
    }
    .num {
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      display: flex;
      align-items: center;
      margin-right: 32px;
      > img {
        width: 10px;
        height: 11px;
        margin-right: 4px;
      }
    }
    .correctRate {
      width: 100px;
      margin-right: 10px;
    }
  }
  .contact {
    width: 60px;
    height: 28px;
    background: #ffedeb;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5e51;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;
  }
}
.empty {
  width: 596px;
  height: 72px;
  background: #f9f9f9;
  border-radius: 8px 8px 8px 8px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-width {
  width: 100%;
  .kc-name {
    max-width: 580px !important;
  }
}
.red {
  color: #ff5e51 !important;
}
.blueOne {
  color: #4a6af0 !important;
}
.blueTwo {
  color: #1061ff !important;
}
.blueOneBorder {
  border: 1px solid #4a6af0 !important;
  background: #ffffff !important;
  &:hover {
    background: #f5f8ff !important;
  }
}
.blueTwoBorder {
  border: 1px solid #1061ff !important;
  background: #ffffff !important;
  &:hover {
    background: #f5f8ff !important;
  }
}
.selectDialog {
  /deep/.el-dialog {
    width: 468px;
    height: 280px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #707070;
  }
  .item-list {
    width: 364px;
    height: 88px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #ff5e51;
    margin: 16px auto 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background: #fff6f5;
    }
    .item-box {
      padding: 0 14px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .item-left {
      display: flex;
      align-items: center;
      > img {
        width: 42px;
        height: 42px;
        margin-right: 16px;
      }
      .tit {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .msg {
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .into {
      width: 14px;
      height: 14px;
      margin: 0;
    }
  }
}
</style>
