<template>
  <div>
    <el-dialog class="new_bac" title="专属教辅" :visible.sync="bookVisible" :before-close="bookClose">
      <div class="textBookWrap">
        <div v-for="(item, index) in textBookList" :key="index" class="textBook">
          <div class="textBookItem">
            <div class="imageWrap">
              <el-image :src="item.faceImg" class="imageItem" />
              <!-- <div class="tabs">可试看</div> -->
            </div>
            <div>
              <div class="titleItem twoEllipsis">{{ item.textbookName }}</div>
              <!-- <div class="author">作者：{{ item.teacherName }}</div> -->
              <div class="sasuke">
                <div class="tag">简介：</div>
                <div class="content" v-html="ImgSizeLimit(item.textBookNote)" />
              </div>
            </div>
            <div class="btn">
              <el-button class="btn1" v-if="!IsPurchase" size="mini" type="danger"
                @click="seeTextbook(item)">免费试看</el-button>
              <el-button class="btn1" v-else size="mini" type="danger" @click="seeTextbook(item)">阅读教材</el-button>
              <div></div>
              <el-button class="btn2" size="mini" type="text" @click="goTextbookDetails(item)">教材详情</el-button>
            </div>
          </div>
          <el-divider />
        </div>
        <div v-if="textBookList.length == 0" class="nothing">
          <div class="nothing-text">暂无内容哦~</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassTeaching, getClassCourseTeaching } from '@/api/home.js'
import { getInfo } from '@/api/cookies'
import Bus from '@/api/bus'
import Vue from 'vue'
export default {
  components: {},
  props: ['bookVisible', 'IsPurchase', 'direction'],
  data() {
    return {
      textBookList: [],
      classesId: null,
      title: null, // 套餐名称

      userInfo: getInfo()
    }
  },
  created() { },
  methods: {
    init(id, IsPurchase, title) {
      this.classesId = id
      this.title = title
      this.getClassTeaching(id)
    },
    /* 教材列表 */
    getClassTeaching(id) {
      if (this.direction) {
        const data = {
          id,
          coursePackageId: this.direction
        }
        getClassCourseTeaching(data).then(res => {
          this.textBookList = res.msg
        })
      } else {
        getClassTeaching(id).then((res) => {
          this.textBookList = res.msg
        })
      }
    },
    /* 点击教材详情 */
    goTextbookDetails(item) {
      this.$router.push({
        path: '/typeclassxq/textbookDetails',
        query: {
          id: item.id,
          title: this.title,
          classesId: this.classesId,
          IsPurchase: this.IsPurchase
        }
      })
    },
    /* 点击阅读/试看教材 */
    seeTextbook(item) {
      const userInfo = getInfo()
      if (userInfo) {
        this.$router.push({
          path: '/VIP/textbookPage',
          query: {
            IsPurchase: this.IsPurchase,
            id: item.id,
            classesId: this.classesId,
            title: this.title
          }
        })
      } else {
        Vue.prototype.goLoginView(false)
      }
    },
    /* 关闭回调 */
    bookClose() {
      this.$emit('bookClose')
    }
  }
}
</script>

<style lang="less" scoped>
.textBook {

  display: flex;
  flex-direction: column;
  align-items: center;

}

.textBook /deep/ .el-divider {
  margin: 0;
  width: 596px;
  background-color: #EEEEEE;
}

.textBookWrap {
  width: 680px;
  height: 419px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 20px;
  overflow-y: scroll;
}

.textBookItem {
  display: flex;
  width: 640px;
  height: 108px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
  border-radius: 10px 10px 10px 10px;
  position: relative;
  padding: 20px;
  margin-top: 20px;
}

.imageWrap {
  width: 74px;
  height: 100px;
  margin-right: 12px;
}

.imageItem {
  width: 74px;
  height: 100px;
  border-radius: 8px 8px 8px 8px;
  top: -30px;
  position: relative;
}

.tabs {
  width: 52px;
  height: 20px;
  background-color: rgba(255, 94, 81, 1);
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 20px;
  border-radius: 8px 0px 8px 0px;
}

.titleItem {
  width: 200px;
  height: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.author {
  margin-top: 16px;
  color: rgba(102, 102, 102, 1);
}

.sasuke {
  display: flex;
  height: 60px;
  margin-top: 10px;
  width: 384px;
  font-size: 12px;
  color: #818395;
  line-height: 20px;
  margin-right: 26px;

  .tag {
    width: 40px;
  }

  .content {
    width: 300px;
    height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.btn1 {
  width: 88px;
  height: 30px;
  border: 1px solid #2485F4 !important;
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #2483F1 !important;
  background: #FFFFFF !important;
}

.btn2 {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #777A82;
  margin-top: 10px;
}

::v-deep .new_bac {
  .el-dialog {
    background: url(~@/assets/img/study/1.png);
    background-size: 100% 100%;
    border-radius: 20px !important;
    width: 690px;
    height: 494px;

    .el-dialog__title {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 22px;
      color: #1D315B;
    }

    .el-dialog__close {
      font-size: 23px;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
  }
}
</style>
