var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warpConss chat-list"},[_vm._l((_vm.CourseList),function(item,index){return _c('div',{key:index,staticClass:"chat-item"},[_c('div',{staticClass:"item-title",class:item.isLive == 0 || item.isLive == 2 ? 'noLive' : ''},[_c('div',{staticClass:"tt-box"},[_c('div',{staticClass:"flex-c ellipsis"},[_c('div',{staticClass:"title ellipsis"},[_c('i',{staticClass:"iconfont icon-a-Union21"}),_vm._v(_vm._s(item.title)+" ")]),_c('div',{staticClass:"time ellipsis"},[(!item.sectionName)?_c('span',[_vm._v(" 共 "+_vm._s(item.ksTime ? item.ksTime : 0)+" 课时")]):_c('span',[_vm._v(_vm._s(_vm._f("format")(item.startTime,"yyyy.MM.dd HH:mm")))])])])])]),(
      (item.docList && item.docList.length > 0) ||
      (item.contactList && item.contactList.length > 0)
    )?_c('div',{staticClass:"but-list"},[_c('div',[(
          _vm.ifClass ? _vm.dealDocType(item, 2) : item.isHasContantJY == 'true'
        )?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.goData(item, 4)}}},[_vm._v(" 随堂讲义 ")]):_vm._e(),(
          _vm.ifClass
            ? item.contactList.length > 0
            : item.isHasContant == 'true'
        )?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.cgeckTask(item)}}},[_vm._v(" 随堂测试 ")]):_vm._e(),(_vm.ifClass ? _vm.dealDocType(item, 1) : item.isHasDoc == 'true')?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.goData(item, 2)}}},[_vm._v(" 随堂资料 ")]):_vm._e()])]):_vm._e()])}),(_vm.dialogVisible)?_c('div',{staticClass:"warpCpons",on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();return _vm.stop()}}},[_c('div',[_c('img',{staticClass:"imga",attrs:{"src":require("@/assets/img/kecheng/No1.png")}}),_c('img',{staticClass:"imgb",attrs:{"src":require("@/assets/img/kecheng/close.png")},on:{"click":_vm.tChuang}}),_c('span',[_vm._v("您暂未购买该课程哦~")])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }