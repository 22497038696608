<template>
  <div class="couserView">

    <!-- 课程资料 -->
    <div v-if="ifNew" v-loading="loading" :element-loading-text="'加载中'">
      <el-collapse v-model="openIndex" accordion @change="collapseChange">
        <el-collapse-item v-for="(item, index) in yearsList" :key="index" :name="index">
          <template slot="title">
            <div class="title">
              <div class="shu" />
              <div class="title-name">
                {{ item.kcName }}
              </div>
            </div>
            <div class="fenge" />
          </template>

          <div class="coupon-list">
            <div v-for="(doc, index) in courseList" :key="index" class="coupon-item">
              <!--   <i class="iconfont icon-a-zu939 img" /> -->
              <div class="img-box">
                <img v-if="doc.filetype == 'excel'" class="img" src="@/assets/img/study/dataXls.png">
                <img v-else-if="doc.filetype == 'word'" class="img" src="@/assets/img/study/dataDoc.png">
                <img v-else-if="doc.filetype == 'pdf'" class="img" src="@/assets/img/study/dataPdf.png">
                <img v-else-if="doc.filetype == 'ppt'" class="img" src="@/assets/img/study/dataPpt.png">
                <img v-else class="img" src="@/assets/img/study/dataFile.png">
              </div>

              <div class="item-content">
                <div class="item-title ellipsis">{{ doc.fileName }}</div>
                <div class="item-size ellipsis">

                  <div v-for="(items, indexs) in doc.labelNameList" :key="indexs">{{ items }}</div>

                </div>
              </div>
              <div class="keep-learn" @click="delow(doc.filePath, doc.fileName, doc)">下载</div>
            </div>
            <div v-if="!courseList || courseList.length == 0" class="nothing">
              <div class="nothing-text">暂无资料哦~</div>
            </div>
          </div>

        </el-collapse-item>
      </el-collapse>
      <div v-if="!yearsList || yearsList.length == 0" class="nothing">
        <div class="nothing-text">暂无资料哦~</div>
      </div>
    </div>


  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { liveEnum } from '@/api/emun'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import kowledgePoints from '@/components/kowledgePoints.vue'
export default {
  components: { kowledgePoints },
  props: {
    // 课程id
    classesId: {
      type: String
    },
    // 是否购买
    IsPurchase: {
      type: Boolean,
      default: false
    },
    // 1 普通线 2 精品线  3 专属线 4 普通线加精品线
    productLine: {
      type: String
    },
    // 课程包id
    direction: {}
  },
  props: ['classesId', 'IsPurchase', 'productLine', 'direction'],
  data() {
    return {
      loadingText: '加载中...',
      liveEnum: liveEnum,
      userInfo: null,
      search: {
        examType: 1 // 1试卷包2：试卷
      },
      list: {
        docPackage: [],
        docList: []
      },
      courseList: [], // 课程列表
      yearsList: [], // 数据列表
      currentYear: '', // 当前年份
      yearKc: [], // 年份列表
      openIndex: 0,
      openChil: 0,
      isShow: false,
      ifNew: true,
      kowledgePointsVisible: false,
      loading: false
    }
  },
  async created() {
    this.userInfo = getInfo()
    await this.getList()
  },
  methods: {
    // 切换年份
    changeYear(item) {
      this.yearsList = item.yearKcNames
      this.currentYear = item.year
      this.collapseChange(0)
    },
    /* 搜索 */
    searchClick() {
      this.list = { docList: [], docPackage: [] }
      this.yearsList = []
      this.getList()
    },
    rideo(val) {
      this.search.examType = val
      this.searchClick()
    },

    // 获取列表
    async getList() {
      const that = this

      this.loading = true
      await know
        .classesButtonKcDataList({
          classesId: this.classesId,
          coursePackageId: this.direction,
          userid:
            this.userInfo && this.userInfo.id ? this.userInfo.id : undefined,
          type: 1,
        })
        .then((res) => {
          if (res.code == 0) {
            this.yearsList = res.data;
          }
        })
      this.collapseChange(0)
      this.$nextTick(() => {
        this.loading = false
      })

    },
    /* 课程展开  */
    async collapseChange(val) {
      if (!val && val !== 0) return
      let item = this.yearsList[val]
      const data = []
      this.loading = true
      await know
        .getDocsPcPageList({ oneLabelId: item.productLine, kcId: item.id })
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.courseList = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /* 下载 */
    delow(filePath, fileName, doc) {
      const userInfo = getInfo()

      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.IsPurchase) {
        const data = {
          zlId: doc.id,
          linkLocation: filePath,
          name: doc.fileName,
          type: 0
        }
        dataClient.downloadRecord(data)
        Vue.prototype.DownloadDoc(filePath, fileName)
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法下载, 请先去购买',
          type: 'error',
          duration: 2000
        })
      }
    },
    /* 试卷包查看 */
    checkClick(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.IsPurchase) {
        this.$router.push(`/data/dataPackageDetail?packageId=${item.typeId}&classesId=${this.classesId}`)
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法查看, 请先去购买',
          type: 'error',
          duration: 2000
        })
      }
    },

    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.id)
      })
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.couserView {
  background: #fff;
  padding-bottom: 20px;

  .year_wrap {
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 32px;
    padding-top: 24px;

    .year_item {
      width: 95px;
      height: 36px;
      border-radius: 8px;
      background-color: #edf0f7;
      color: #565962;
      text-align: center;
      line-height: 36px;
      margin-right: 24px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .on {
      border: 1px solid #ff5e51;
      background-color: #fff7f6;
      color: #ff5e51;
      font-weight: bold;
    }
  }
}

.typeRadio {
  margin: 24px 32px 12px;
  display: flex;

  .el-divider {
    margin: 0px 24px;
  }

  .item {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    cursor: pointer;
  }

  .on {
    color: #ff5e51;
  }
}

/deep/.el-collapse {
  border: none !important;

  .redRadio {
    display: none;
  }

  .radio-area {
    display: inline-block;
  }

  .is-active {
    .redRadio {
      display: inline-block;
    }
  }

  .el-icon-arrow-right {
    transform: rotate(90deg);
  }

  .is-active.el-icon-arrow-right {
    transform: rotate(270deg);
  }

  .el-collapse-item__header {
    height: 58px;
    padding: 19px 32px 19px 0px;
    border: none !important;
    position: relative;

    .title {
      display: flex;
      height: 20px;
      align-items: center;

      .title-name {
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        margin-right: 20px;

        .iconfont {
          color: #333333;
          font-size: 12px;
          margin-right: 9px;
        }
      }

      .shu {
        width: 4px;
        height: 20px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        margin-right: 29px;
      }
    }

    .fenge {
      // width: 816px;
      height: 1px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      left: 32px;
      right: 32px;
    }
  }

  .is-active {
    .shu {
      background: #ff5e51 !important;
    }
  }

  .el-collapse-item__content {
    border: none !important;
    padding-bottom: 0px;
  }

  .el-collapse-item__wrap {
    border: none !important;
  }
}

/* 按章列表 */
/deep/ .docList {
  padding: 0px 32px;

  .el-collapse {
    border: none !important;
  }

  .el-collapse-item__header {
    height: 52px;
    background: #f5f7f9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    padding: 0px 12px;
    margin-top: 12px;
  }

  .docList-type {
    height: 20px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #ff7b1c;
    padding: 0px 6px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .docList-title {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    .title-text,
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }

    .flex-c {
      width: calc(100% - 100px);
    }

    .tips {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      flex-shrink: 0;
      margin-right: 17px;
    }

    .iconfont {
      font-size: 16px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .open {
    .iconfont {
      transform: rotate(90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .uni-collapse-cell__title-text {
    border: none !important;
  }

  .uni-collapse-cell--open {
    .uni-collapse-cell__title-text {
      border: none !important;
    }
  }

  .item-content {
    height: 16px !important;

    // display: inline-grid;
    .item-title {
      font-size: 14px !important;
    }
  }
}

/* 随堂资料 */
.coupon-list {
  padding: 0px 28px;

  .coupon-item {
    height: 72px;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;

    .img-box {
      flex-shrink: 0;

      .img {
        margin: 30px auto;
        width: 40px;
        height: 40px;
      }
    }

    .item-content {
      width: calc(100% - 124px);
      text-align: left;
      margin: 0px 12px;
      // height: 100px;

      .item-title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }

      .item-size {
        max-width: 500px;
        display: flex;
        margin-top: 10px;
        overflow-x: hidden;

        >div {
          padding: 1px 4px;
          background: #FFEEED;
          border-radius: 4px 4px 4px 4px;

          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #FF5E51;
          margin-right: 5px;
        }
      }
    }

    .btn_item {
      width: 74px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #dddddd;
      font-size: 14px;
      color: #666666;
      text-align: center;
      line-height: 30px;
      margin-right: 20px;
      cursor: pointer;
    }

    .keep-learn {
      flex-shrink: 0;
      height: 30px;
      line-height: 30px;
    }
  }
}

/* 套餐资料 */
.examType-two {
  .list-two {
    background: #fff;
    padding: 0px 32px;

    .item {
      margin-bottom: 0px;
      border-bottom: 1px solid #eee;
      height: 148px;
      border-radius: 0px;
      padding: 0px;

      .item-content {
        height: 100px;
      }
    }
  }

  .class-list {
    padding: 0px 32px !important;

    .coupon-item {
      border-bottom: 1px solid #eee;
      height: 148px;

      .img-box {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        background: #f5f7f9;
        border-radius: 8px 8px 8px 8px;

        .img {
          margin: 30px auto;
        }
      }

      .item-content {
        display: grid;
      }
    }
  }
}

.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
</style>
